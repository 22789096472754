export default {
	forms: {
		edit: {
			config: {
				fields: {
					value: {},
					name: {},
					description: {}
				}
			}
		},
		list: {
			config: {
				sorting: true,
				fields: {
					id: {
						config: {
							hide: true
						}
					},
					value: {
						config: {
							sorting: 'roles.value'
						}
					},
					name: {
						config: {
							sorting: 'roles.name'
						}
					},
					description: {}
				}
			}
		},
		select: {
			panel: {
				height: '500px',
				width: '40%'
			},
			config: {
				fields: {
					value: {},
					name: {}
				}
			}
		}
	}
}